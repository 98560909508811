<template>
  <footer class="main-footer">
    <div class="main-footer--tuev-trustpilot">
      <TuevSeal v-if="!props.blok.isTuevFooterHidden" :copy="true" />
      <TrustPilot v-if="!props.blok.isTrustpilotFooterHidden" />
    </div>
    <nav class="main-footer--navigation">
      <PflegeDivider
        v-if="props.blok.footerAddLinks?.length > 0"
        borderColor="mint-darker"
        height="0"
      />

      <ul v-if="props.blok.footerAddLinks?.length > 0" class="main-footer__list">
        <li
          v-for="(item, index) in props.blok.footerAddLinks"
          :key="`${item._uid}_${index}`"
          class="main-footer__list-item"
        >
          <NuxtLink
            :target="item.link?.target"
            :title="item.linkTitel"
            :to="useCalcedLink(item)"
            class="main-footer__list-link"
          >
            <PflegeIcon
              v-if="item.icon !== ''"
              :name="item.icon"
              aria-hidden="true"
              size="l"
              class="main-footer__list-icon"
            />
            <span v-html="item.linkLabel" />
          </NuxtLink>
        </li>
      </ul>

      <PflegeDivider
        v-if="props.blok.footerAddLinks?.length > 0"
        borderColor="mint-darker"
        height="0"
      />

      <ul class="main-footer__list">
        <li
          v-for="(item, index) in props.blok.disclaimerLinks"
          :key="`${item._uid}_${index}`"
          class="main-footer__list-item"
        >
          <NuxtLink
            :target="item.link?.target"
            :title="item.linkTitel"
            :to="useCalcedLink(item)"
            class="main-footer__list-link"
          >
            <span v-html="item.linkLabel" />
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script setup>
import { PflegeIcon, PflegeDivider } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true,
    default: () => {
      return {
        isTuevFooterHidden: false,
        isTrustpilotFooterHidden: true,
        footerAddLinks: [],
        disclaimerLinks: []
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.main-footer {
  background-color: var(--mint-light);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1.75rem;
  padding-block: 1.25rem;

  &--tuev-trustpilot {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;

    & > * {
      padding: 0.5rem;
    }

    @media (max-width: 576px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: 576px) {
      align-items: start;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    margin: 0;
    padding: 1.25rem;

    @media (min-width: 576px) {
      flex-direction: row;
    }
  }

  &__list-item {
    margin-block-end: 0;
  }

  &__list-link {
    display: flex;
    align-items: center;
    color: currentColor;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.5;
    text-decoration: none;

    &:is(:hover, :active) {
      text-decoration: underline;
      color: currentColor;
    }
  }

  &__list-icon {
    margin-right: 0.38888rem;
    color: currentColor;
  }
}
</style>
